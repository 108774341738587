import { Suspense, lazy } from "react";
import HashLoader from "react-spinners/HashLoader";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
// import BlogDetails from "../../components/BlogDetails/BlogDetails";
// import BlogForm from "../../components/BlogForm/BlogForm";
const Home = lazy(() => import("../../pages/Home"));
const Mobiles = lazy(() => import("../../pages/Mobiles"));
const Laptops = lazy(() => import("../../pages/Laptops"));
const Tvs = lazy(() => import("../../pages/Tvs"));
const Appliances = lazy(() => import("../../pages/Appliances"));
// const Reviews = lazy(() => import('../../pages/Reviews'));
const Electronics = lazy(() => import("../../pages/Electronics"));
const Tablets = lazy(() => import("../../pages/Tablets"));
const Computers = lazy(() => import("../../pages/Computers"));
const Earphones = lazy(() => import("../../pages/Earphones"));
const Product = lazy(() => import("../../pages/Product"));
const CreateComparison = lazy(() => import("../../pages/CreateComparison"));
const EditComparisons = lazy(() => import("../../pages/EditComparisons"));
const Refrigerator = lazy(() => import("../../pages/Refrigerator"));
const NewPage = lazy(() => import("../../pages/NewPage"));
const WashingMachine = lazy(() => import("../../pages/WashingMachine"));
const SmartWatches = lazy(() => import("../../pages/SmartWatches"));
const MobileAccessories = lazy(() => import("../../pages/MobileAccessories"));
const MoreApps = lazy(() => import("../../pages/MoreApps"));
const Comparisons = lazy(() => import("../../pages/Comparisons"));
const Deal = lazy(() => import("../../pages/Deal"));
const AirConditioner = lazy(() => import("../../pages/AirConditioner"));
const Reviews = lazy(() => import("../../components/Review/index"));
const RelatedNews = lazy(() => import("../../components/RelatedNews/index"));
const About = lazy(() => import("../../pages/AboutUs"));
const Contact = lazy(() => import("../../pages/Contact/Contact"));
const Blogs = lazy(() => import("../../pages/Blogs/Blogs"));
const BlogDetails = lazy(() =>
  import("../../components/BlogDetails/BlogDetails")
);
const BlogForm = lazy(() => import("../../components/BlogForm/BlogForm"));
const SmartPhones = lazy(() => import("../../components/smartphoness"));
const SmartPhonesList = lazy(() => import("../../components/smartphoneList"));
const Tabletss = lazy(() => import("../../components/Tabletss"));
const TabletsList = lazy(() => import("../../components/TabletList"));
const Laptopss = lazy(() => import("../../components/Laptops"));
const LaptopList = lazy(() => import("../../components/LaptopList"));
const TVs = lazy(() => import("../../components/TVs"));
const TVList = lazy(() => import("../../components/TVList"));
const ACs = lazy(() => import("../../components/ACs"));
const ACList = lazy(() => import("../../components/ACList"));
const Washings = lazy(() => import("../../components/Washings"));
const WashingList = lazy(() => import("../../components/WashingList"));
const Earbuds = lazy(() => import("../../components/Earbuds"));
const EarbudList = lazy(() => import("../../components/EarbudList"));
const Earphone = lazy(() => import("../../components/Earphones"));
const EarphoneList = lazy(() => import("../../components/EarphoneList"));
const Headphones = lazy(() => import("../../components/Headphones"));
const HeadphoneList = lazy(() => import("../../components/HeadphoneList"));
const Refrigerators = lazy(() => import("../../components/Refrigerators"));
const RefrigeratorList = lazy(() =>
  import("../../components/RefrigeratorList")
);
const Smartwatches = lazy(() => import("../../components/Smartwatches"));
const SmartWatchList = lazy(() => import("../../components/SmartWatchList"));
const Fitnessbands = lazy(() => import("../../components/Fitnessbands"));
const FitnessbandList = lazy(() => import("../../components/FitnessbandList"));
const Powerbanks = lazy(() => import("../../components/Powerbanks"));
const PowerbankList = lazy(() => import("../../components/PowerbankList"));
const Airpurifiers = lazy(() => import("../../components/Airpurifiers"));
const AirpurifierList = lazy(() => import("../../components/AirpurifierList"));
const Microwaves = lazy(() => import("../../components/Microwaves"));
const MicrowaveList = lazy(() => import("../../components/MicrowaveList"));
const Aircoolers = lazy(() => import("../../components/Aircoolers"));
const AircoolerList = lazy(() => import("../../components/AircoolerList"));
const Chimneys = lazy(() => import("../../components/Chimneys"));
const ChimneyList = lazy(() => import("../../components/ChimneyList"));
const Hairdryers = lazy(() => import("../../components/Hairdryers"));
const HairdryerList = lazy(() => import("../../components/HairdryerList"));
const Inductions = lazy(() => import("../../components/Inductions"));
const InductionList = lazy(() => import("../../components/InductionList"));
const Irons = lazy(() => import("../../components/Irons"));
const IronList = lazy(() => import("../../components/IronList"));
const Trimmers = lazy(() => import("../../components/Trimmers"));
const TrimmerList = lazy(() => import("../../components/TrimmerList"));
const Haircurlers = lazy(() => import("../../components/Haircurlers"));
const HaircurlerList = lazy(() => import("../../components/HaircurlerList"));
const Sandwichmakers = lazy(() => import("../../components/Sandwichmakers"));
const SandwichmakerList = lazy(() =>
  import("../../components/SandwichmakerList")
);
const Powertoothbrushes = lazy(() =>
  import("../../components/Powertoothbrushes")
);
const PowertoothbrushList = lazy(() =>
  import("../../components/PowertoothbrushList")
);
const Electrickettles = lazy(() => import("../../components/Electrickettles"));
const ElectrickettleList = lazy(() =>
  import("../../components/ElectrickettleList")
);
const Shavers = lazy(() => import("../../components/Shavers"));
const ShaverList = lazy(() => import("../../components/ShaverList"));
const Waterpurifiers = lazy(() => import("../../components/Waterpurifiers"));
const WaterpurifierList = lazy(() =>
  import("../../components/WaterpurifierList")
);
const Epilators = lazy(() => import("../../components/Epilators"));
const EpilatorList = lazy(() => import("../../components/EpilatorList"));
const Hairstraightners = lazy(() =>
  import("../../components/Hairstraightners")
);
const HairstraightnerList = lazy(() =>
  import("../../components/HairstraightnerList")
);
const Vacuumcleaners = lazy(() => import("../../components/Vacuumcleaners"));
const VacuumcleanerList = lazy(() =>
  import("../../components/VacuumcleanerList")
);
const Handblenders = lazy(() => import("../../components/Handblenders"));
const HandblenderList = lazy(() => import("../../components/HandblenderList"));
const Mixerjuicers = lazy(() => import("../../components/Mixerjuicers"));
const MixerjuicerList = lazy(() => import("../../components/MixerjuicerList"));
const Geysers = lazy(() => import("../../components/Geysers"));
const GeyserList = lazy(() => import("../../components/GeyserList"));

const PublicPaths = [
  {
    path: "/",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Home />
      </Suspense>
    ),
  },
  {
    path: "/new",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <NewPage />
      </Suspense>
    ),
  },
  {
    path: "/news",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <RelatedNews />
      </Suspense>
    ),
  },
  {
    path: "/blog/:slug",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <BlogDetails />
      </Suspense>
    ),
  },
  {
    path: "/blogform",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <BlogForm />
      </Suspense>
    ),
  },

  {
    path: "/more",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <MoreApps />
      </Suspense>
    ),
  },
  {
    path: "/smartphones",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <SmartPhones />
      </Suspense>
    ),
  },
  {
    path: "/smartphones/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <SmartPhonesList />
      </Suspense>
    ),
  },
  {
    path: "/smart-tablets",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Tabletss />
      </Suspense>
    ),
  },
  {
    path: "/smart-tablets/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <TabletsList />
      </Suspense>
    ),
  },
  {
    path: "/laptop",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Laptopss />
      </Suspense>
    ),
  },
  {
    path: "/laptop/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <LaptopList />
      </Suspense>
    ),
  },
  {
    path: "/tvs",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <TVs />
      </Suspense>
    ),
  },
  {
    path: "/tvs/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <TVList />
      </Suspense>
    ),
  },
  {
    path: "/acs",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <ACs />
      </Suspense>
    ),
  },
  {
    path: "/acs/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <ACList />
      </Suspense>
    ),
  },
  {
    path: "/washmac",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Washings />
      </Suspense>
    ),
  },
  {
    path: "/washmac/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <WashingList />
      </Suspense>
    ),
  },
  {
    path: "/earbud",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Earbuds />
      </Suspense>
    ),
  },
  {
    path: "/earbud/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <EarbudList />
      </Suspense>
    ),
  },
  {
    path: "/earphone",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Earphone />
      </Suspense>
    ),
  },
  {
    path: "/earphone/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <EarphoneList />
      </Suspense>
    ),
  },
  {
    path: "/headphone",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Headphones />
      </Suspense>
    ),
  },
  {
    path: "/headphone/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <HeadphoneList />
      </Suspense>
    ),
  },
  {
    path: "/refrigerator",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Refrigerators />
      </Suspense>
    ),
  },
  {
    path: "/refrigerator/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <RefrigeratorList />
      </Suspense>
    ),
  },
  {
    path: "/smartwatch",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Smartwatches />
      </Suspense>
    ),
  },
  {
    path: "/smartwatch/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <SmartWatchList />
      </Suspense>
    ),
  },
  {
    path: "/fitnessband",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Fitnessbands />
      </Suspense>
    ),
  },
  {
    path: "/fitnessband/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <FitnessbandList />
      </Suspense>
    ),
  },
  {
    path: "/powerbank",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Powerbanks />
      </Suspense>
    ),
  },
  {
    path: "/powerbank/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <PowerbankList />
      </Suspense>
    ),
  },
  {
    path: "/airpurifier",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Airpurifiers />
      </Suspense>
    ),
  },
  {
    path: "/airpurifier/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <AirpurifierList />
      </Suspense>
    ),
  },
  {
    path: "/microwave",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Microwaves />
      </Suspense>
    ),
  },
  {
    path: "/microwave/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <MicrowaveList />
      </Suspense>
    ),
  },
  {
    path: "/aircooler",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Aircoolers />
      </Suspense>
    ),
  },
  {
    path: "/aircooler/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <AircoolerList />
      </Suspense>
    ),
  },
  {
    path: "/chimney",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Chimneys />
      </Suspense>
    ),
  },
  {
    path: "/chimney/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <ChimneyList />
      </Suspense>
    ),
  },
  {
    path: "/hairdryer",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Hairdryers />
      </Suspense>
    ),
  },
  {
    path: "/hairdryer/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <HairdryerList />
      </Suspense>
    ),
  },
  {
    path: "/induction",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Inductions />
      </Suspense>
    ),
  },
  {
    path: "/induction/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <InductionList />
      </Suspense>
    ),
  },
  {
    path: "/iron",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Irons />
      </Suspense>
    ),
  },
  {
    path: "/iron/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <IronList />
      </Suspense>
    ),
  },
  {
    path: "/trimmer",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Trimmers />
      </Suspense>
    ),
  },
  {
    path: "/trimmer/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <TrimmerList />
      </Suspense>
    ),
  },
  {
    path: "/haircurler",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Haircurlers />
      </Suspense>
    ),
  },
  {
    path: "/haircurler/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <HaircurlerList />
      </Suspense>
    ),
  },
  {
    path: "/sandwichmaker",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Sandwichmakers />
      </Suspense>
    ),
  },
  {
    path: "/sandwichmaker/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <SandwichmakerList />
      </Suspense>
    ),
  },
  {
    path: "/toothbrush",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Powertoothbrushes />
      </Suspense>
    ),
  },
  {
    path: "/toothbrush/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <PowertoothbrushList />
      </Suspense>
    ),
  },
  {
    path: "/electrickettle",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Electrickettles />
      </Suspense>
    ),
  },
  {
    path: "/electrickettle/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <ElectrickettleList />
      </Suspense>
    ),
  },
  {
    path: "/shaver",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Shavers />
      </Suspense>
    ),
  },
  {
    path: "/shaver/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <ShaverList />
      </Suspense>
    ),
  },
  {
    path: "/waterpurifier",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Waterpurifiers />
      </Suspense>
    ),
  },
  {
    path: "/waterpurifier/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <WaterpurifierList />
      </Suspense>
    ),
  },
  {
    path: "/epilator",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Epilators />
      </Suspense>
    ),
  },
  {
    path: "/epilator/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <EpilatorList />
      </Suspense>
    ),
  },
  {
    path: "/hairstraightner",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Hairstraightners />
      </Suspense>
    ),
  },
  {
    path: "/hairstraightner/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <HairstraightnerList />
      </Suspense>
    ),
  },
  {
    path: "/vacuumcleaner",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Vacuumcleaners />
      </Suspense>
    ),
  },
  {
    path: "/vacuumcleaner/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <VacuumcleanerList />
      </Suspense>
    ),
  },
  {
    path: "/handblender",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Handblenders />
      </Suspense>
    ),
  },
  {
    path: "/handblender/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <HandblenderList />
      </Suspense>
    ),
  },
  {
    path: "/mixerjuicer",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Mixerjuicers />
      </Suspense>
    ),
  },
  {
    path: "/mixerjuicer/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <MixerjuicerList />
      </Suspense>
    ),
  },
  {
    path: "/geyser",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Geysers />
      </Suspense>
    ),
  },
  {
    path: "/geyser/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <GeyserList />
      </Suspense>
    ),
  },
  {
    path: "/mobiles",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Mobiles />
      </Suspense>
    ),
  },
  {
    path: "/mobile_accessories/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/mobile_accessories/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/mobile_accessories/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/mobile_accessories",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <MobileAccessories />
      </Suspense>
    ),
  },
  {
    path: "/mobiles/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/mobiles/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/mobiles/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/tablets",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Tablets />
      </Suspense>
    ),
  },
  {
    path: "/tablets/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/tablets/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/tablets/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/laptops",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Laptops />
      </Suspense>
    ),
  },
  {
    path: "/laptops/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/laptops/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/laptops/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/pcs",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Computers />
      </Suspense>
    ),
  },
  {
    path: "/pcs/:id",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/pcs/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/pcs/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/televisions",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Tvs />
      </Suspense>
    ),
  },
  {
    path: "/televisions/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/televisions/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/televisions/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/appliances",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Appliances />
      </Suspense>
    ),
  },
  {
    path: "/appliances/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/appliances/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/appliances/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/electronics",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Electronics />
      </Suspense>
    ),
  },
  {
    path: "/electronics/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/electronics/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },

  {
    path: "/electronics/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },

  {
    path: "/reviews",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Reviews />
      </Suspense>
    ),
  },
  {
    path: "/deals",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Deal />
      </Suspense>
    ),
  },
  {
    path: "/smart_watches",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <SmartWatches />
      </Suspense>
    ),
  },
  {
    path: "/smart_watches/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/smart_watches/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/smart_watches/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/earphones",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Earphones />
      </Suspense>
    ),
  },
  {
    path: "/earphones/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/earphones/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/earphones/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/air_conditioners",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <AirConditioner />
      </Suspense>
    ),
  },
  {
    path: "/air_conditioners/:name/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/air_conditioners/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/air_conditioners/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/comparison/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <EditComparisons />
      </Suspense>
    ),
  },
  {
    path: "/refrigerators",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Refrigerator />
      </Suspense>
    ),
  },
  {
    path: "/refrigerators/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/refrigerators/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/refrigerators/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/washing_machines",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <WashingMachine />
      </Suspense>
    ),
  },

  {
    path: "/washing_machines/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: "/washing_machines/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },

  {
    path: "/washing_machines/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/appliances",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Appliances />
      </Suspense>
    ),
  },
  {
    path: "/appliances/:id",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Product />
      </Suspense>
    ),
  },

  {
    path: "/appliances/comparison",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <CreateComparison />
      </Suspense>
    ),
  },
  {
    path: "/appliances/comparisons",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Comparisons />
      </Suspense>
    ),
  },
  {
    path: "/comparison/:id",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <EditComparisons />
      </Suspense>
    ),
  },
  {
    path: "about",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <About />
      </Suspense>
    ),
  },
  {
    path: "/contact",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <Contact />
      </Suspense>
    ),
  },
  {
    path: "/blogs",
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Blogs />
      </Suspense>
    ),
  },
  {
    path: "*",
    Content: () => (
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <HashLoader color="#1D61E7" size={50} />
          </div>
        }
      >
        <NotFoundPage />
      </Suspense>
    ),
  },
];
export default PublicPaths;
