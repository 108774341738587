import Layout from "../../components/Layout";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../../components/PrivateRoute";
import PrivatePaths from "../ProtectedRoutes/index.";
import PublicPaths from "../PublicRoutes";
import AuthPaths from "../AuthRoutes";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
const RoutesHOC = () => {
  return (
    <>
      <Routes>
        <Route>
          {AuthPaths.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.Content />}
            />
          ))}
        </Route>
        <Route element={<Layout />}>
          {PublicPaths.map((route) => (   
            <Route
              key={route.path}
              path={route.path}
              element={<route.Content />}
            />
          ))}
          <Route element={<PrivateRoute />}>
            {PrivatePaths.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.Content />}
              />
            ))}
          </Route>
        </Route>
        <Route path="*" component={NotFoundPage} />
      </Routes>
    </>
  );
};
export default RoutesHOC;
